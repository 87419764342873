import { EventLoggerInstanceName } from '../constants'
import { getCommonViewportProperties } from '../helpers/getCommonVieportProperties'
import { IEvent, IEventLogger, IEventLogParams } from '../types'

export const enum Events {
  PAGE_SHOWED = 'page_showed',
  WEB_ONB_BTN_CLICKED = 'web_onb_btn_clicked',
  WATCH_VIDEO_BTN_CLICKED = 'watch_video_btn_clicked',
  STORE_BTN_CLICKED = 'store_btn_clicked',
  CONTACT_BTN_CLICKED = 'contact_btn_clicked',
  SOCIAL_MEDIA_BTN_CLICKED = 'social_media_btn_clicked',
  FORM_SUBMITTED = 'form_submitted',
  LOGO_BTN_CLICKED = 'logo_btn_clicked',
  TRY_WOOFZ_BTN_CLICKED = 'try_woofz_btn_clicked',
  REVIEW_SPENDS_BTN_CLICKED = 'review_spends_btn_clicked',
  TOGGLE_TURNED_ON = 'toggle_turned_on',
  ARTICLE_SHOWED = 'article_showed',
  CONFIG_LOADED = 'config_loaded',
}

export class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )
    this.loggers = new Map(entriesArr)
    this.notifyInitFinished()
  }

  logPageShowed = ({ pageName }: { pageName: string }): void => {
    const event = Events.PAGE_SHOWED
    const eventProperty = {
      page_name: pageName,
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logArticleShowed = ({
    article,
    source,
  }: {
    article: string
    source: string
  }): void => {
    const event = Events.ARTICLE_SHOWED
    const eventProperty = {
      article,
      source,
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logCalculatorPageShowed = ({
    pageName,
    source,
    country,
    breed,
    size,
    plan,
    totalPrice,
    toggle,
  }: {
    pageName: string
    source?: string
    country?: string
    breed?: string
    size?: string
    plan?: string
    totalPrice?: number
    toggle?: string
  }): void => {
    const event = Events.PAGE_SHOWED
    const eventProperty = {
      page_name: pageName,
      ...(source && { source }),
      ...(country && { chosen_geo: country }),
      ...(breed && { chosen_breed: breed }),
      ...(size && { chosen_size: size }),
      ...(plan && { chosen_training_format: plan }),
      ...(totalPrice && { total_cost: totalPrice }),
      ...(toggle && { toggle_status: toggle }),
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logLogoButtonClicked = ({
    pageName,
    place,
  }: {
    pageName: string
    place: string
  }): void => {
    const event = Events.LOGO_BTN_CLICKED
    const eventProperty = {
      page_name: pageName,
      place,
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logWebOBButtonClicked = (
    { pageName }: { pageName: string },
    cb: () => void,
  ): void => {
    const event = Events.WEB_ONB_BTN_CLICKED
    const eventProperty = {
      page_name: pageName,
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty, cb })
  }

  logWatchVideoButtonClicked = ({ pageName }: { pageName: string }): void => {
    const event = Events.WATCH_VIDEO_BTN_CLICKED
    const eventProperty = {
      page_name: pageName,
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logStoreButtonClicked = ({
    pageName,
    store,
    cb,
  }: {
    pageName: string
    store: string
    cb?: () => void
  }): void => {
    const event = Events.STORE_BTN_CLICKED
    const eventProperty = {
      page_name: pageName,
      store,
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty, cb })
  }

  logContactUsButtonClicked = ({ pageName }: { pageName: string }): void => {
    const event = Events.CONTACT_BTN_CLICKED
    const eventProperty = {
      page_name: pageName,
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logSocialMediaButtonClicked = ({ type }: { type: string }): void => {
    const event = Events.SOCIAL_MEDIA_BTN_CLICKED
    const eventProperty = {
      type,
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logFormSubmitted = ({ pageName }: { pageName: string }): void => {
    const event = Events.FORM_SUBMITTED
    const eventProperty = {
      page_name: pageName,
      ...getCommonViewportProperties(),
    }

    this.logEventOrPushToQueue({ event, eventProperty })
  }

  logWoofzBtnClicked(cb: () => void): void {
    const event = Events.TRY_WOOFZ_BTN_CLICKED
    this.logEventOrPushToQueue({ event, cb })
  }

  logReviewSpendsBtnClicked(): void {
    const event = Events.REVIEW_SPENDS_BTN_CLICKED
    this.logEventOrPushToQueue({ event })
  }

  logToggleTurnedOn(): void {
    const event = Events.TOGGLE_TURNED_ON
    this.logEventOrPushToQueue({ event })
  }

  logConfigLoaded = ({
    abVariant,
    abSegmentName,
    pageName,
  }: {
    abVariant: string
    abSegmentName: string
    pageName: string
  }): void => {
    const event = Events.CONFIG_LOADED
    const eventProperty = {
      ab_variant: abVariant,
      ab_segment_name: abSegmentName,
      page_name: pageName,
      ...getCommonViewportProperties(),
    }
    this.logEventOrPushToQueue({ event, eventProperty })
  }

  private logEventOrPushToQueue({
    event,
    eventProperty,
    cb,
  }: IEventLogParams): void {
    if (this.loggers?.size) {
      this.logEvent({ event, eventProperty, cb })
    } else {
      this.eventsQueue.push({ event, eventProperty })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent({ event, eventProperty }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({ event, eventProperty, cb }: IEventLogParams): void {
    this.loggers?.forEach((logger) => logger.log({ event, eventProperty, cb }))
  }
}

export const eventLogger = new EventLoggerService()
