import { PlatformOS } from '../constants'

export function getOperatingSystemVersion(): string {
  const { userAgent } = window.navigator

  const [operatingSystemVersion = PlatformOS.UNKNOWN] =
    /[^()]+(?=\))/.exec(userAgent) || []

  return operatingSystemVersion
}
