import { initializeApp } from 'firebase/app'
import {
  fetchAndActivate,
  getRemoteConfig,
  getBoolean,
  getString,
} from 'firebase/remote-config'
import { FirebaseRemoteConfigKey } from '../../constants'
import { initEventLogger } from './initEventLogger'

const credentials = {
  apiKey: 'AIzaSyBdj0hED68auzR_7dTiUVSHcJBPVEEFOTU',
  authDomain: 'woofz-3c4c1.firebaseapp.com',
  databaseURL: 'https://woofz-3c4c1.firebaseio.com',
  projectId: 'woofz-3c4c1',
  storageBucket: 'woofz-3c4c1.appspot.com',
  messagingSenderId: '735987244362',
  appId: '1:735987244362:web:5e5130c3721878144fb5b3',
  measurementId: 'G-ZDF0LDFDLB',
}

export class FirebaseService {
  private remoteConfig: any
  private shouldSendAmplitudeEvent: boolean

  constructor() {
    this.shouldSendAmplitudeEvent = false
  }

  async init() {
    const app = initializeApp(credentials)
    const remoteConfig = getRemoteConfig(app)
    await fetchAndActivate(remoteConfig)

    this.remoteConfig = remoteConfig
    this.shouldSendAmplitudeEvent = getBoolean(
      this.remoteConfig,
      FirebaseRemoteConfigKey.WEBSITE_AMPLITUDE_ANALYTICS,
    )

    if (this.shouldSendAmplitudeEvent) {
      initEventLogger()
    }
  }

  public getAmplitudeFlag() {
    return this.shouldSendAmplitudeEvent
  }

  public getAmplitudeValueByKey(key: FirebaseRemoteConfigKey) {
    return this.remoteConfig ? getString(this.remoteConfig, key) : {}
  }
}

export const firebaseService = new FirebaseService()
