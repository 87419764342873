import { Amplitude } from './amplitude.service'
import { EventLoggerInstanceName } from '../constants'
import { getGIAAmplitudeOptions } from '../helpers/getGIAAmplitudeOptions'
import { eventLogger } from './eventLogger.service'
import { getIsProdEnv } from '../../helpers/getIsProdEnv'
import {
  ANALYTICS_PAGE,
  FirebaseRemoteConfigKey,
  LOGGER_WAS_INITIALIZED,
  PAGES_MAP,
} from '../../constants'
import { getMainPageButtonText } from '../../helpers/getMainPageButtonText'
import { getCurrentPage } from '../../helpers/getCurrentPage'

export const initEventLogger = () => {
  const isProdEnvironment = getIsProdEnv()
  const amplitudeService = new Amplitude({
    apiKey: '26bf9ad22cf44f0afda3593518c68564',
    instanceName: EventLoggerInstanceName.AMPLITUDE,
    isProdEnvironment,
  })

  const giaService = new Amplitude({
    apiKey: '232a07af-4bc0-4592-adeb-d5e80e38297a',
    instanceName: EventLoggerInstanceName.GIA,
    options: getGIAAmplitudeOptions(),
    isProdEnvironment,
  })

  const { abSegmentName } = getMainPageButtonText()
  const page = getCurrentPage()

  amplitudeService.configure()
  giaService.configure()

  eventLogger.init(amplitudeService, giaService)
  eventLogger.logConfigLoaded({
    abVariant: FirebaseRemoteConfigKey.USE_ALT_CTA_BTN_WEBSITE,
    abSegmentName,
    pageName: PAGES_MAP[page] || ANALYTICS_PAGE.MAIN,
  })
  sessionStorage.setItem(LOGGER_WAS_INITIALIZED, 'true')
}
