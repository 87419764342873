import { FirebaseRemoteConfigKey } from '../constants'
import { firebaseService } from '../event-logger/services/firebase.service'

export const getMainPageButtonText = () => {
  const buttonTextConfig = firebaseService.getAmplitudeValueByKey(
    FirebaseRemoteConfigKey.USE_ALT_CTA_BTN_WEBSITE,
  )
  const buttonText =
    typeof buttonTextConfig === 'string' ? JSON.parse(buttonTextConfig) : {}
  const abVariant = 'text'
  const abSegmentName = buttonText[abVariant] || 'Start Your Journey'

  return { abSegmentName, abVariant }
}
